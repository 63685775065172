import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let contactForm = {
      senderName: name,
      senderEmail: email,
      senderPhone: phone,
      message: message,
    };

    console.log(contactForm);
  };
  return (
    <div className="contact__container container">
      <div className="contact__wrapper">
        <h2 className="title__h2">Контакт</h2>
        <div className="contact__form">
          <div className="form">
            <form action="https://formspree.io/f/mzboawdv" method="POST">
              <TextField
                label="Име Презиме"
                variant="outlined"
                className="order__input"
                value={name}
                name="name"
                type="text"
                required
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Е-маил адреса"
                variant="outlined"
                className="order__input"
                value={email}
                type="email"
                name="email"
                required
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Tелефонски број"
                variant="outlined"
                className="order__input"
                value={phone}
                type="number"
                name="phone"
                required
                fullWidth
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextField
                label="Вашата порака"
                variant="outlined"
                className="order__input"
                value={message}
                multiline
                name="message"
                rows={4}
                required
                fullWidth
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                style={{
                  backgroundColor: "var(--colorPrimary)",
                  color: "white",
                }}
              >
                Испрати
              </Button>
            </form>
          </div>
          <div className="contact__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11963.260070342594!2d22.6406868!3d41.4432353!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x111235751fddb00a!2sBuzz%20Mobile%20-%20Store%20%26%20Repair%20Shop!5e0!3m2!1sen!2smk!4v1649079446682!5m2!1sen!2smk"
              width="100%"
              height="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              allowfullscreen=""
              loading="lazy"
              title="buzz mobile location"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
