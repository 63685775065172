import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import SearchIcon from "@mui/icons-material/Search";
import { getSearchResults } from "../api/apiCalls";
import SingleSearch from "./SingleSearch";

function SearchFilter() {
  const [open, setOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(null);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(false);
  const [searchFailed, setSearchFailed] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    if (searchName === "") {
      setError(true);
    } else {
      setLoading(true);
      getSearchResults(searchName).then(
        (res) => {
          setResults(res);
          setLoading(null);
          setError(false);

          if (res.length <= 0) {
            setSearchFailed(true);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };
  return (
    <>
      <SearchIcon
        onClick={handleClickOpen}
        aria-label="search"
        style={{
          cursor: "pointer",
          fontSize: "28px",
          marginLeft: "5px",
        }}
      />
      <Dialog
        open={open}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "#ffffffeb",
          },
        }}
        onClose={handleClose}
      >
        <DialogTitle style={{ fontFamily: "var(--titleFont)" }}>
          Пребарај мобилни телефони / галантерија...
        </DialogTitle>
        <DialogContent>
          <div className="search__field__container">
            <TextField
              label="Име на телефон / галантерија"
              variant="outlined"
              style={{ marginTop: "30px" }}
              onChange={(e) => setSearchName(e.target.value)}
              value={searchName}
              className="field__search"
            />
            <Button
              variant="contained"
              endIcon={<SearchIcon />}
              style={{
                marginLeft: "10px",
                backgroundColor: "var(--colorPrimary)",
              }}
              onClick={handleSearch}
              disabled={loading}
              className="field__btn"
            >
              Пребарај
            </Button>
          </div>
          <CloseIcon onClick={handleClose} className="dialog__close" />

          <div className="search__results">
            {results.length > 0 ? (
              results.map((result, index) => (
                <SingleSearch {...result} key={index} />
              ))
            ) : searchFailed ? (
              <p>
                <i>Пронајдени 0 резултати</i>
              </p>
            ) : (
              <p></p>
            )}
            {error && (
              <Alert severity="warning">
                Внесете збор за да почнете со пребарување
              </Alert>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SearchFilter;
