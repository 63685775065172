import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { getSingleProduct } from "../api/apiCalls";
import { useLocation } from "react-router-dom";

//swiperjs
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CameraIcon from "@mui/icons-material/Camera";
import MemoryIcon from "@mui/icons-material/Memory";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import OrderProduct from "./OrderProduct";
import WatchIcon from "@mui/icons-material/Watch";

SwiperCore.use([Navigation, Thumbs]);

function SingleProduct(id) {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const location = useLocation();
  const productId = location.pathname.split("/")[2];
  const [colorC, setcolorC] = useState();
  const [firstLoad, setfirstLoad] = useState(true);
  const [storageC, setstorageC] = useState();
  const [price, setPrice] = useState();

  useEffect(() => {
    let isMounted = true;

    window.scrollTo(0, 0);

    if (firstLoad) {
      getSingleProduct(productId).then(
        (res) => {
          if (isMounted) {
            setProduct(res);
          }
        },
        (err) => {
          console.log(err);
        }
      );
      setfirstLoad(false);
    }

    if (product) {
      setcolorC(product.color[0]);
      setstorageC(product.priceStorage[0].storage);
      setPrice(product.priceStorage[0].price);
      document.title = `${product.title} | Buzz Mobile Store`;
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [product]);

  const getPrice = () => {
    const filteredResult = product.priceStorage.find(
      (e) => e.storage == storageC
    );

    return filteredResult.price;
  };

  const handlePriceStorage = (storage) => {
    setPrice(storage.price);
    setstorageC(storage.storage);
  };

  return (
    <div className="single__product__container container">
      {loading ? (
        <LinearProgress />
      ) : (
        <div className="single__product__wrapper">
          <div className="single__product__images">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                width: "100%",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Navigation, Thumbs]}
              className="single__product__gallery"
            >
              {product.img.map((item, index) => (
                <SwiperSlide>
                  <img src={item} alt="phone" key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              slidesPerView={3}
              modules={[Navigation, Thumbs]}
              className="single__product__thumbs"
              style={{ width: "100%" }}
            >
              {product.img.map((item, index) => (
                <SwiperSlide>
                  <img src={item} alt="phone" height={100} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="single__product__info">
            <div className="single__product__title">
              <p className="sp__title">{product.title}</p>
              <p className="sp__brand">{product.brand}</p>
            </div>
            <div className="single__product__specs">
              <p className="sp__specs">Спецификации</p>
              <div className="sp__wrapper">
                <div className="single__product__specs-item">
                  {product.title.includes("Watch") ? (
                    <WatchIcon />
                  ) : (
                    <PhoneIphoneIcon />
                  )}

                  <p>{product.display}</p>
                </div>
                <div className="single__product__specs-item">
                  <CameraIcon />
                  <p>{product.camera}</p>
                </div>
                <div className="single__product__specs-item">
                  <MemoryIcon />
                  <p>{product.ram}</p>
                </div>
                <div className="single__product__specs-item">
                  <Battery0BarIcon />
                  <p>{product.battery}</p>
                </div>
              </div>
            </div>
            <div className="sp__choice">
              <div className="single__product__colors">
                <p>Бои:</p>
                {product.color.map((color, index) => (
                  <span
                    key={index}
                    value={color}
                    style={{ backgroundColor: `${color}` }}
                    onClick={(e) => setcolorC(color)}
                    className={
                      "sp__colors" +
                      " " +
                      (color == colorC ? "active-color" : "")
                    }
                  ></span>
                ))}
              </div>
              <div className="single__product__storage">
                {product.title.includes("Watch") ? (
                  <p>Дијаметар:</p>
                ) : (
                  <p>Меморија:</p>
                )}

                {product.priceStorage.map((storage, index) => (
                  <span
                    key={index}
                    onClick={() => handlePriceStorage(storage)}
                    value={storage.storage}
                    className={
                      "sp__storage" +
                      " " +
                      (storage.storage === storageC ? "active-storage" : "")
                    }
                  >
                    {storage.storage}
                  </span>
                ))}
              </div>
            </div>
            <div className="single__product__price">
              <p className="sp__price">
                {" "}
                {parseFloat(getPrice()).toLocaleString("en")} <span>ден.</span>
              </p>
            </div>
            <OrderProduct
              title={product.title}
              color={colorC}
              storage={storageC}
              price={price}
              img={product.img[0]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleProduct;
