import React from "react";
import Skeleton from "@mui/material/Skeleton";

function FeaturedProductSkeleton() {
  return (
    <div className="featured__collection__singlep" style={{textAlign:'center'}}>
      <Skeleton variant="rectangular" height={250} width={180} />
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={100} />
    </div>
  );
}

export default FeaturedProductSkeleton;
