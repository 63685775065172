import React, { useEffect, useState } from "react";

import CartItem from "../components/CartItem";

import Alert from "@mui/material/Alert";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import OrderProduct from "./OrderProduct";

function Cart() {
  const [products, setProducts] = useState(
    JSON.parse(sessionStorage.getItem("cart")) || []
  );
  const [Nquantity, setNquantity] = useState(1);
  const [totalProductp, setTotalproductp] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let current = sessionStorage.getItem("cart");
    if (current) {
      calculateTotalPrice(JSON.parse(sessionStorage.getItem("cart")));
    }
  }, []);

  const deleteCartProduct = (id) => {
    let current = sessionStorage.getItem("cart");

    if (!current) {
      current = [];
    } else {
      current = JSON.parse(current);
    }
    current.splice(
      current.findIndex((item) => item.id === id),
      1
    );

    sessionStorage.setItem("cart", JSON.stringify(current));

    setProducts(products.filter((item) => item.id !== id));
    calculateTotalPrice(current);
    setShow(true);
    setTimeout(function () {
      setShow(false);
    }, 2000);
  };

  const calculateTotalPrice = (products) => {
    const initialValue = 0;
    setTotalproductp(
      products.reduce(
        (accumulator, current) =>
          accumulator + current.price * current.quantity,
        initialValue
      )
    );
  };

  return (
    <div className="cart__container container">
      <div className="cart__wrapper">
        <h2 className="title__h2">Кошничка</h2>

        {products.length > 0 ? (
          <>
            <div className="cart__grid">
              <div className="cart__grid__titles">
                <p></p>
                <p style={{ textAlign: "left" }}>Производ</p>
                <p>Цена</p>
                <p>Количина</p>
                <p>Вкупно</p>
              </div>
              {products.map((product, index) => (
                <CartItem
                  product={product}
                  key={index}
                  deleteCartProduct={deleteCartProduct}
                  calculateTotalPrice={calculateTotalPrice}
                />
              ))}
            </div>
            <div className="cart__total">
              <p>
                ВКУПНО ЦЕНА:{" "}
                <span style={{ fontWeight: "600" }}>
                  {parseFloat(totalProductp).toLocaleString("en")} ден.
                </span>
              </p>
            </div>
            <div className="cart__buttons">
              <div className="continue__shopping continue">
                <Link to="/">Продолжи со купување</Link>
              </div>
              <OrderProduct products={sessionStorage.getItem("cart")} cartOrder={true}/>
            </div>
          </>
        ) : (
          <>
            <Alert severity="error">Вашата кошничка е празна</Alert>
            <div className="continue__shopping">
              <Link to="/">Продолжи со купување</Link>
            </div>
          </>
        )}
      </div>

      {show && (
        <div className="addtocart__popup cart">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="warning">
              <p>Продуктот е избришан од вашата кошничка</p>
            </Alert>
          </Stack>
        </div>
      )}
    </div>
  );
}

export default Cart;
