import React, { useEffect } from "react";
import FeaturedCollection from "../components/FeaturedCollection";
import GamingGearBanner from "../components/GamingGearBanner";
import HomeBanner from "../components/HomeBanner";
import OurOffers from "../components/OurOffers";
import ServiceHome from "../components/ServiceHome";

function HomePage() {
  useEffect(() => {
    document.title = "Buzz Mobile Store - Премиум мобилара";
  });
  return (
    <div>
      <HomeBanner />
      <OurOffers />
      <FeaturedCollection
        gear={"НАЈДОБРА ПОНУДА"}
        results={10}
        featured={"/products?category=telefoni&promotion=true"}
      />
      <GamingGearBanner />
      <FeaturedCollection
        gear={"ПРОФЕСИОНАЛНА ГЕЈМИНГ ОПРЕМА"}
        results={10}
        featured={"/accessories?category=kgalanterija&promotion=true"}
      />
      <ServiceHome />
    </div>
  );
}

export default HomePage;
