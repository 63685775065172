import React from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

import { Link } from "react-router-dom";
import WishlistSingle from "./WishlistSingle";

function Wishlist() {
  const wishlistproducts = useSelector((state) => state.wishlist);
  return (
    <div className="wishlist__container container">
      <div className="wishlist__wrapper">
        <h2 className="title__h2" style={{ textAlign: "left" }}>
          Листа на желби
        </h2>
        <div className="wishlist__items">
          {wishlistproducts.length > 0 ? (
            wishlistproducts.map((wish, index) => (
              <WishlistSingle product={wish} key={index} />
            ))
          ) : (
            <>
              <Alert severity="error">Вашата листа на желби е празна</Alert>
              <div className="continue__shopping wishlist">
                <Link to="/">Продолжи со купување</Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Wishlist;
