import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Slider from "@mui/material/Slider";

import { getProductsByBrand, getProductsByCategory } from "../api/apiCalls";
import { useLocation } from "react-router-dom";
import FeaturedCollectionProduct from "./FeaturedCollectionProduct";
import { Alert, LinearProgress } from "@mui/material";

function Category() {
  const [filter, setFilter] = useState("");
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);
  const [firstMount, setFirstMount] = useState(true);
  const [sortedProducts, setsortedProducts] = useState();

  const location = useLocation();
  const categoryTitle = location.pathname.split("/")[1];
  const brand = location.pathname.split("/")[2];

  useEffect(() => {
    let isMounted = true;

    if (firstMount) {
      if (categoryTitle === "telefoni") {
        getProductsByBrand(brand).then(
          (res) => {
            if (isMounted) {
              setProducts(res);
              setLoading(false);
              setFirstMount(false);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        getProductsByCategory(brand).then(
          (res) => {
            if (isMounted) {
              setProducts(res);
              setLoading(false);
              setFirstMount(false);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }

    if (products) {
      console.log();
      if (categoryTitle === "telefoni") {
        let title = products[0].categories[0];
        document.title = `${
          title[0].toUpperCase() + title.slice(1)
        } | Buzz Mobile Store - Премиум мобилара `;
      } else {
        document.title = "Галантерија | Buzz Mobile Store - Премиум мобилара ";
      }

      setsortedProducts(products);
    }
  }, [products]);

  const filterProducts = (e) => {
    setFilter(e.target.value);

    if (e.target.value === "Релевантност") {
      let sorted = products.slice().sort((a, b) => b.title > a.title);
      setsortedProducts(sorted);
    } else if (e.target.value === "Датум на објава") {
      let sorted = products
        .slice()
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setsortedProducts(sorted);
    } else if (e.target.value === "Цена: Ниска - Висока") {
      let sorted = products
        .slice()
        .sort((a, b) => a.priceStorage[0].price - b.priceStorage[0].price);
      setsortedProducts(sorted);
    } else if (e.target.value === "Цена: Висока - Ниска") {
      let sorted = products
        .slice()
        .sort((a, b) => b.priceStorage[0].price - a.priceStorage[0].price);
      setsortedProducts(sorted);
    } else {
      return products;
    }
  };

  return (
    <div className="category__container container">
      <div className="category__wrapper">
        {products &&
          (products[0].categories[0] === "mgalanterija" ? (
            <h2 className="title__h2">МОБИЛНА ГАЛАНТЕРИЈА</h2>
          ) : products[0].categories[0] === "kgalanterija" ? (
            <h2 className="title__h2">КОМПЈУТЕРСКА ГАЛАНТЕРИЈА</h2>
          ) : (
            <h2 className="title__h2">
              МОБИЛНИ ТЕЛЕФОНИ{" "}
              <span style={{ fontWeight: "400" }}>{products[0].brand}</span>
            </h2>
          ))}
        {loading ? (
          <LinearProgress />
        ) : (
          <div className="category__content">
            {categoryTitle === "telefoni" && (
              <div className="category__filter">
                <p>Филтри</p>
                <div className="filter__relev">
                  <FormControl style={{ width: "250px" }}>
                    <InputLabel>СОРТИРАЈ</InputLabel>
                    <Select
                      value={filter}
                      label="СОРТИРАЈ"
                      onChange={(e) => {
                        filterProducts(e);
                      }}
                    >
                      <MenuItem value={"Релевантност"}>Релевантност</MenuItem>
                      <MenuItem value={"Датум на објава"}>
                        Датум на објава
                      </MenuItem>
                      <MenuItem value={"Цена: Ниска - Висока"}>
                        Цена: Ниска - Висока
                      </MenuItem>
                      <MenuItem value={"Цена: Висока - Ниска"}>
                        Цена: Висока - Ниска
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

            <div className="category__phones">
              {products.length != null ? (
                sortedProducts.map((product, index) => (
                  <FeaturedCollectionProduct
                    product={product}
                    key={index}
                    category={true}
                  />
                ))
              ) : (
                <Alert severity="warning">Категоријата е празна</Alert>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Category;
