import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="notfound__container container">
      <div className="notfound__wrapper">
        <h2 className="title__h2">Страницата не е пронајдена</h2>
        <div className="continue__shopping" style={{ textAlign: "center" }}>
          <Link to="/">Продолжи со купување</Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
