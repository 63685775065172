import React, { useEffect, useState } from "react";

//logo
import logo from "../assets/buzz-mobile-noicons.webp";
//mui icons
import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchFilter from "./SearchFilter";
import Navbar from "./Navbar";
import NavbarMobile from "./NavbarMobile";
import AvTimerIcon from "@mui/icons-material/AvTimer";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 5,
  },
}));

function Header() {
  const [cartNumber, setcartNumber] = useState(0);
  const wishNumber = useSelector((state) => state.wishlist.length);
  const [openMenu, setOpenMenu] = useState(false);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if ("cart" in sessionStorage) {
      setcartNumber(JSON.parse(sessionStorage.getItem("cart")).length);
    }
  }, []);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
    setFade(!fade);

    if (!openMenu) {
      document.body.classList.add("nav-open");
    } else {
      document.body.classList.remove("nav-open");
    }
  };

  return (
    <>
      <div className="top__header__container">
        <div className="top__header__wrapper container">
          <div className="top__header__col1">
            <div className="phone-calls">
              <CallIcon
                fontSize="small"
                className="icon"
                style={{ color: "#35f29a" }}
              />{" "}
              <div className="phone-calls__info">
                <a className="header__icons" href="tel:+38976810012">
                  076-810-012
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "35px",
              }}
            >
              <AvTimerIcon style={{ color: "#35f29a" }} />{" "}
              <span>09:00 - 19:00</span>
            </div>
          </div>
          <div className="top__header__col2">
            <IconButton
              aria-label="cart"
              className="icon"
              component={Link}
              to="/cart"
            >
              <StyledBadge badgeContent={cartNumber} color="success">
                <ShoppingCartIcon style={{ color: "#9e9c9c" }} />
              </StyledBadge>
            </IconButton>
            <span
              style={{ marginRight: "10px", marginLeft: "5px" }}
              className="toph__s"
            >
              <Link className="header__icons" to="/cart">
                кoшничка
              </Link>
            </span>

            <IconButton
              aria-label="cart"
              className="icon"
              component={Link}
              to="/wishlist"
            >
              <StyledBadge badgeContent={wishNumber} color="error">
                <FavoriteIcon style={{ color: "#9e9c9c" }} />
              </StyledBadge>
            </IconButton>
            <span style={{ marginLeft: "10px" }}>
              <Link className="header__icons wishlist" to="/wishlist">
                желби
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="header__container">
        <div className="header__wrapper container">
          <div className="header__logo">
            <Link to="/" className="links">
              <img src={logo} alt="buzz mobile logo" />
            </Link>
          </div>
          <div className="header__nav">
            <Navbar />
            <nav className="header__mobile">
              <ul>
                <li>
                  <IconButton
                    aria-label="cart"
                    className="icon"
                    fontSize="large"
                    component={Link}
                    to="/cart"
                  >
                    <StyledBadge badgeContent={cartNumber} color="success">
                      <ShoppingCartIcon
                        style={{ color: "#9e9c9c" }}
                        fontSize="medium"
                      />
                    </StyledBadge>
                  </IconButton>
                </li>
                <li className="fav__li">
                  <IconButton
                    aria-label="favourite"
                    className="icon"
                    fontSize="large"
                  >
                    <StyledBadge badgeContent={wishNumber} color="error">
                      <FavoriteIcon
                        style={{ color: "#9e9c9c" }}
                        fontSize="medium"
                      />
                    </StyledBadge>
                  </IconButton>
                </li>
                <li>
                  <SearchFilter fontSize="medium" />
                </li>
                <li>
                  <MenuIcon
                    fontSize="large"
                    style={{ marginLeft: "20px" }}
                    onClick={handleMenu}
                  />

                  <NavbarMobile
                    openMenu={openMenu}
                    fade={fade}
                    handleMenu={handleMenu}
                  />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
