import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import ReCAPTCHA from "react-google-recaptcha";
import { Button, LinearProgress } from "@mui/material";
import { submitOrder } from "../api/apiCalls";

import Alert from "@mui/material/Alert";

function OrderProduct({
  title,
  color,
  storage,
  price,
  img,
  products,
  cartOrder,
}) {
  let cartProducts;
  if (cartOrder) {
    cartProducts = products;
    cartProducts = JSON.parse(cartProducts);
  }

  const [open, setOpen] = useState(false);
  const [verify, setVerify] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [verifyError, showverifyError] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [orderCreated, setOrderCreated] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCaptcha = () => {
    setVerify(true);
  };

  const handleOrder = (e) => {
    e.preventDefault();

    let order = {};
    let product = {
      title: title,
      color: color,
      storage: storage,
      price: price,
    };

    if (cartOrder) {
      order = {
        products: cartProducts,
        name: name,
        lastName: lastName,
        address: address,
        phoneNumber: phone,
        city: city,
        amount: calculateTotalPrice(cartProducts),
      };
    } else {
      order = {
        products: product,
        name: name,
        lastName: lastName,
        address: address,
        phoneNumber: phone,
        city: city,
        amount: product.price,
      };
    }
    setSubmitting(true);
    submitOrder(order).then(
      (res) => {
        setOrderCreated(true);
        setSubmitting(false);
        if (cartOrder) {
          sessionStorage.removeItem("cart");
        }
      },
      (err) => {
        setOrderCreated(false);
      }
    );
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  };

  const calculateTotalPrice = (products) => {
    const initialValue = 0;

    return products.reduce(
      (accumulator, current) => accumulator + current.price * current.quantity,
      initialValue
    );
  };
  return (
    <>
      <div className="single__product__btn" onClick={handleOpen}>
        <a>Нарачај</a>
      </div>
      <Dialog
        open={open}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            backgroundColor: "#ffffffeb",
          },
        }}
        onClose={handleClose}
        className="orderproduct__container"
      >
        <DialogTitle
          style={{ fontFamily: "var(--titleFont)", textAlign: "center" }}
        >
          {!orderCreated && <p>Нарачај</p>}
        </DialogTitle>
        <DialogContent>
          {submitting && <LinearProgress />}
          {orderCreated ? (
            <>
              <Alert severity="success">
                Вашата нарачка е успешно направена
                <p style={{ fontWeight: "600" }}>
                  Доставата се врши преку курирска служба, очекувајте да биде
                  кај вас во следните 2-3 дена.
                </p>
              </Alert>
            </>
          ) : (
            <div className="order__details">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  style={{
                    backgroundColor: "var(--colorPrimary)",
                    color: "white",
                  }}
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                >
                  <Typography>
                    {" "}
                    {cartOrder ? "Детали за продуктите" : "Детали за продуктот"}
                  </Typography>
                </AccordionSummary>
                '
                <AccordionDetails>
                  {cartOrder ? (
                    cartProducts.map((product, index) => (
                      <div className="product__details cartOrder" key={index}>
                        <img
                          src={product.img}
                          alt={product.title}
                          height={80}
                        />
                        <p className="title__p">{product.title}</p>

                        {color && (
                          <span
                            style={{ backgroundColor: `${product.color}` }}
                            className="color__p"
                          ></span>
                        )}

                        {product.storage &&
                          (product.title.includes("Watch") ? (
                            <p className="storage__p">
                              Дијаметар:{" "}
                              <span style={{ fontWeight: "600" }}>
                                {product.storage}
                              </span>
                            </p>
                          ) : (
                            <p className="storage__p">
                              Меморија:{" "}
                              <span style={{ fontWeight: "600" }}>
                                {product.storage}
                              </span>
                            </p>
                          ))}
                        <p className="storage__p">
                          Количина:{" "}
                          <span style={{ fontWeight: "600" }}>
                            {product.quantity}
                          </span>
                        </p>

                        <p className="price__p">
                          Цена:{" "}
                          <span style={{ fontWeight: "600" }}>
                            {parseFloat(
                              product.price * product.quantity
                            ).toLocaleString("en")}{" "}
                            <span>ден.</span>
                          </span>
                        </p>
                        {index === cartProducts.length - 1 && (
                          <p className="price__full">
                            Вкупно цена:{" "}
                            <span style={{ fontWeight: "600" }}>
                              {parseFloat(
                                calculateTotalPrice(cartProducts)
                              ).toLocaleString("en")}{" "}
                              <span>ден.</span>
                            </span>
                          </p>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="product__details">
                      <img src={img} alt={title} height={80} />
                      <p className="title__p">{title}</p>

                      {color && (
                        <span
                          style={{ backgroundColor: `${color}` }}
                          className="color__p"
                        ></span>
                      )}
                      {storage &&
                        (title.includes("Watch") ? (
                          <p className="storage__p">
                            Дијаметар:{" "}
                            <span style={{ fontWeight: "600" }}>{storage}</span>
                          </p>
                        ) : (
                          <p className="storage__p">
                            Меморија:{" "}
                            <span style={{ fontWeight: "600" }}>{storage}</span>
                          </p>
                        ))}

                      <p className="price__p">
                        Цена:{" "}
                        <span style={{ fontWeight: "600" }}>
                          {parseFloat(price).toLocaleString("en")}{" "}
                          <span>ден.</span>
                        </span>
                      </p>

                      <p className="price__full">
                        Вкупно цена:{" "}
                        <span style={{ fontWeight: "600" }}>
                          {parseFloat(price).toLocaleString("en")}{" "}
                          <span>ден.</span>
                        </span>
                      </p>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>

              <p className="info__order">Податоци за нарачка</p>
              <div className="order__form">
                <form onSubmit={handleOrder}>
                  <TextField
                    label="Име"
                    variant="outlined"
                    fullWidth
                    className="order__input"
                    required
                    onChange={(e) => setName(e.target.value)}
                    helperText={name === "" ? "Полето е задолжително" : " "}
                  />
                  <TextField
                    label="Презиме"
                    variant="outlined"
                    fullWidth
                    className="order__input"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    helperText={lastName === "" ? "Полето е задолжително" : " "}
                  />
                  <TextField
                    label="Телефонски број"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="order__input"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    helperText={phone === "" ? "Полето е задолжително" : " "}
                  />
                  <TextField
                    label="Адреса"
                    variant="outlined"
                    fullWidth
                    className="order__input"
                    required
                    onChange={(e) => setAddress(e.target.value)}
                    helperText={address === "" ? "Полето е задолжително" : " "}
                  />
                  <TextField
                    label="Град"
                    variant="outlined"
                    fullWidth
                    className="order__input"
                    required
                    onChange={(e) => setCity(e.target.value)}
                    helperText={city === "" ? "Полето е задолжително" : " "}
                  />
                  {/* <ReCAPTCHA
                    sitekey="6Lef20kfAAAAAC7UDeK3ugqdAeuUy1brn17h3Rbh"
                    onChange={handleCaptcha}
                  />
                  {showverifyError && (
                    <Alert severity="error">
                      Верификацијата е задолжителна.
                    </Alert>
                  )} */}
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "var(--colorPrimary)",
                      padding: "5px 25px",
                      margin: "15px 0px 15px 0px",
                    }}
                  >
                    Нарачај
                  </Button>
                </form>
              </div>
            </div>
          )}
        </DialogContent>
        <CloseIcon onClick={handleClose} className="dialog__close__order" />
      </Dialog>
    </>
  );
}

export default OrderProduct;
