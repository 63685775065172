import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

//mui
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import {
  addWish,
  clearItems,
  increaseItem,
  setItems,
} from "../store/slices/userSlice";

function FeaturedCollectionProduct({ product, category }) {
  const [img1, setImg1] = useState(product.img[0]);
  const [img2, setImg2] = useState("");
  const [showFav, setShowFav] = useState(false);
  const [show, setShow] = useState(false);

  const wishlist = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  useEffect(() => {
    if (product.img[1] !== null) {
      setImg2(product.img[1]);
    } else {
      setImg2(product.img[0]);
    }
  }, []);

  const handleClick = () => {
    let current = sessionStorage.getItem("cart");

    if (!current) {
      current = [];
    } else {
      current = JSON.parse(current);
    }

    let item = {};
    if ("priceStorage" in product) {
      item = {
        id: product._id,
        title: product.title,
        price: product.priceStorage[0].price,
        storage: product.priceStorage[0].storage,
        img: product.img[0],
        categories: product.categories[0],
        quantity: 1,
      };
    } else {
      item = {
        id: product._id,
        title: product.title,
        price: product.price,
        img: product.img[0],
        categories: product.categories[0],
        quantity: 1,
      };
    }

    if (current.some((e) => e.id === item.id)) {
      let objIndex = current.findIndex((obj) => obj.id == item.id);
      current[objIndex].quantity += 1;
    } else {
      current.push(item);
    }

    sessionStorage.setItem("cart", JSON.stringify(current));

    setShow(true);
    setTimeout(function () {
      setShow(false);
    }, 2000);
  };

  const handleClickFav = () => {
    let item = {};
    if ("priceStorage" in product) {
      item = {
        id: product._id,
        title: product.title,
        price: product.priceStorage[0].price,
        storage: product.priceStorage[0].storage,
        img: product.img[0],
        quantity: 1,
      };
    } else {
      item = {
        id: product._id,
        title: product.title,
        price: product.price,
        img: product.img[0],
        quantity: 1,
      };
    }

    if (!wishlist.some((e) => e.id === item.id)) {
      dispatch(addWish(item));
      setShowFav(true);
      setTimeout(function () {
        setShowFav(false);
      }, 2000);
    }
  };

  return (
    <div className="featured__collection__singlep">
      {category ? (
        <div className="single__img">
          <div>
            <LazyLoadImage src={product.img[0]} alt={product.title} />
          </div>
        </div>
      ) : (
        <div
          className="single__img"
          // onMouseOver={() => setHover(true)}
          // onMouseOut={() => setHover(false)}
        >
          {/* {product.img.length > 1 ? (
            <>
              <div>
                <LazyLoadImage
                  src={img1}
                  alt={product.title}
                  className="firstIMG"
                />
                <img
                  src={img2}
                  className={"fp_second" + " " + `${hover ? "overlay" : ""}`}
                />
              </div>
            </>
          ) : (
            <Link to={"/product/accessories/" + product._id} className="links">
              <LazyLoadImage src={img1} alt={product.title} />
          </Link>
          )} */}
          {product.categories[0] === "telefoni" ? (
            <Link to={"/product/" + product._id} className="links">
              <LazyLoadImage src={img1} alt={product.title} />
            </Link>
          ) : (
            <Link to={"/product/accessories/" + product._id} className="links">
              <LazyLoadImage src={img1} alt={product.title} />
            </Link>
          )}
        </div>
      )}

      <div>
        {product.categories[0] === "telefoni" ? (
          <Link to={"/product/" + product._id} className="links">
            <p className="fp__title">{product.title}</p>
          </Link>
        ) : (
          <Link to={"/product/accessories/" + product._id} className="links">
            <p className="fp__title">{product.title}</p>
          </Link>
        )}

        <div className="single__price">
          {"priceStorage" in product ? (
            <>
              {product.priceStorage.length > 1 ? (
                <p className="fp__price">
                  од{" "}
                  {parseFloat(product.priceStorage[0].price).toLocaleString(
                    "en"
                  )}{" "}
                  ден.
                </p>
              ) : (
                <p className="fp__price">
                  {parseFloat(product.priceStorage[0].price).toLocaleString(
                    "en"
                  )}{" "}
                  ден.
                </p>
              )}
            </>
          ) : (
            <p className="fp__price">
              {parseFloat(product.price).toLocaleString("en")} ден.
            </p>
          )}
        </div>
      </div>
      <div className="featured__collection__icons">
        <AddShoppingCartIcon onClick={handleClick} />
        <FavoriteIcon onClick={handleClickFav} />
      </div>
      {show && (
        <div className="addtocart__popup">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              <p>
                {product.title} е додаден во{" "}
                <Link to="/" className="links">
                  кошничката
                </Link>
              </p>
            </Alert>
          </Stack>
        </div>
      )}
      {showFav && (
        <div className="addtocart__popup">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info">
              <p>
                {product.title} е додаден во{" "}
                <Link to="/" className="links">
                  листата на желби
                </Link>
              </p>
            </Alert>
          </Stack>
        </div>
      )}
    </div>
  );
}

export default FeaturedCollectionProduct;
