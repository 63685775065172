import React, { useEffect, useState } from "react";

import img from "../assets/video-banner.webp";
import webmvideo from "../assets/video/buzz-website-video.webm";
import mp4video from "../assets/video/buzz-website-video.mp4";
import battery from "../assets/service-imgs/battery-problem.webp";
import screen from "../assets/service-imgs/glass-prolbme.webp";
import camera from "../assets/service-imgs/camera-problem.webp";
import speaker from "../assets/service-imgs/speaker-problem.webp";
import display from "../assets/service-imgs/broken-display.webp";
import network from "../assets/service-imgs/network-loss.webp";
import buttons from "../assets/service-imgs/button-problems.webp";
import software from "../assets/service-imgs/softawre-rpoblem.webp";
import { Link } from "react-router-dom";

//swiperjs
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SingleService from "./SingleService";
SwiperCore.use([Pagination, Navigation, Autoplay]);

const services = [
  {
    img: battery,
    title: "БАТЕРИЈА",
  },
  {
    img: display,
    title: "ЕКРАН",
  },
  {
    img: camera,
    title: "КАМЕРА",
  },
  {
    img: speaker,
    title: "ЗВУЧНИК",
  },
  {
    img: screen,
    title: "СТАКЛО",
  },
  {
    img: network,
    title: "МРЕЖА",
  },
  {
    img: buttons,
    title: "КОПЧИЊА",
  },
  {
    img: software,
    title: "СОФТВЕР",
  },
];

function ServiceHome() {
  const [show, setShow] = useState(null);
  const [slide, setSlide] = useState(3);

  useEffect(() => {
    window.addEventListener("resize", handleResizeSwiper);
    return () => window.removeEventListener("resize", handleResizeSwiper);
  }, []);

  const handleResizeSwiper = () => {
    if (window.innerWidth < 768) {
      setShow(true);
    } else {
      setShow(false);
    }

    if (window.innerWidth < 375) {
      setSlide(2);
    } else {
      setSlide(3);
    }
  };

  return (
    <div className="services__containerhp" id="service">
      <div className="services__wrapperhp container">
        <div className="services__video">
          <video poster={img} id="bgvid" playsInline autoPlay muted loop>
            <source src={webmvideo} type="video/webm" />
            <source src={mp4video} type="video/mp4" />
          </video>
        </div>
        <div className="services__title">
          <p>Брзо дијагностицирање и решавање на проблемот со вашиот телефон</p>
        </div>
        {show ? (
          <Swiper
            className="mySwiper"
            style={{ width: "100%", textAlign: "center", marginTop: "55px" }}
            pagination={{
              dynamicBullets: true,
            }}
            slidesPerView={slide}
            modules={[Autoplay, Pagination, Navigation]}
            loop={true}
            navigation={true}
          >
            {services.map((service, index) => (
              <SwiperSlide>
                <SingleService {...service} key={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="buzz-service-items" id="buzz-slider">
            {services.map((service, index) => (
              <div className="buzz-service-item" key={index}>
                <img src={service.img} alt="battery-phone-icon" />
                <p>{service.title}</p>
              </div>
            ))}
          </div>
        )}

        <div className="services__button">
          <Link to="/" className="links">
            сервисирај
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceHome;
