import React from "react";

import SavingsIcon from "@mui/icons-material/Savings";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
function OurOffers() {
  return (
    <div className="marquee">
      <div className="track">
        <div className="content">
          <div className="marquee__container">
            <div className="marquee__item">
              <SavingsIcon />
              <p>Најповолни цени</p>
            </div>
            <div className="marquee__item">
              <LocalShippingIcon />
              <p>Бесплатна достава</p>
            </div>
            <div className="marquee__item">
              <PhoneIphoneIcon />
              <p>Професионален сервис</p>
            </div>
            <div className="marquee__item">
              <AlarmOnIcon />
              <p>Брз сервис до 30мин.</p>
            </div>
            <div className="marquee__item">
              <WorkspacePremiumIcon />
              <p>Задоволни клиенти</p>
            </div>
            <div className="marquee__item">
              <SavingsIcon />
              <p>Најповолни цени</p>
            </div>
            <div className="marquee__item">
              <LocalShippingIcon />
              <p>Бесплатна достава</p>
            </div>
            <div className="marquee__item">
              <PhoneIphoneIcon />
              <p>Професионален сервис</p>
            </div>
            <div className="marquee__item">
              <AlarmOnIcon />
              <p>Брз сервис до 30мин.</p>
            </div>
            <div className="marquee__item">
              <WorkspacePremiumIcon />
              <p>Задоволни клиенти</p>
            </div>
            <div className="marquee__item">
              <SavingsIcon />
              <p>Најповолни цени</p>
            </div>
            <div className="marquee__item">
              <LocalShippingIcon />
              <p>Бесплатна достава</p>
            </div>
            <div className="marquee__item">
              <PhoneIphoneIcon />
              <p>Професионален сервис</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurOffers;
