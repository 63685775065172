import React from "react";

import img from "../assets/gaminggear-banner.webp";

function GamingGearBanner() {
  return (
    <div className="gaminggear__container container">
      <div className="gaminggear__wrapper">
        <div className="gaminggear__img">
          <img src={img} alt="gaming operma" />
        </div>
        <div className="gaminggear__desc">
          <p>
            Професионална гејминг опрема и додатоци, кои ќе ви овозможат врвно
            искуство во гејминг светот{" "}
          </p>
          <a href="/galanterija/gslusalki">повеќе</a>
        </div>
      </div>
    </div>
  );
}

export default GamingGearBanner;
