import React, { useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteWish } from "../store/slices/userSlice";

function WishlistSingle({ product }) {
  const [showAdded, setShowAdded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(deleteWish(product.id));

    showDelete(true);
    setTimeout(function () {
      setShowDelete(false);
    }, 2000);
  };

  const handleAddCartItem = () => {
    let current = sessionStorage.getItem("cart");

    if (!current) {
      current = [];
    } else {
      current = JSON.parse(current);
    }

    if (current.some((e) => e.id === product.id)) {
      let objIndex = current.findIndex((obj) => obj.id === product.id);
      current[objIndex].quantity += 1;
    } else {
      current.push(product);
    }

    sessionStorage.setItem("cart", JSON.stringify(current));

    setShowAdded(true);
    setTimeout(function () {
      setShowAdded(false);
    }, 2000);
  };
  return (
    <div className="wishlist__item">
      <div className="item__img">
        <img src={product.img} alt="wishlist" height={100} />
      </div>
      <div className="item__info">
        <p>{product.title}</p>
        <p className="item__price">
          {parseFloat(product.price).toLocaleString("en")} ден.
        </p>
      </div>
      <div className="item__action">
        <Link to={"/product/" + product.id} className="links">
          <SearchIcon />
        </Link>
        <AddShoppingCartIcon
          color="primary"
          className="cartIcon"
          onClick={handleAddCartItem}
        />
        <DeleteOutlineIcon
          fontSize="medium"
          style={{ color: "#d32f2f", marginLeft: "5px" }}
          onClick={deleteItem}
        />
      </div>

      {showAdded && (
        <div className="addtocart__popup">
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              <p>
                {product.title} е додаден во{" "}
                <Link to="/" className="links">
                  кошничката
                </Link>
              </p>
            </Alert>
          </Stack>
        </div>
      )}
    </div>
  );
}

export default WishlistSingle;
