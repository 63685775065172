import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { Alert, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import logo from "../assets/buzz-mobile-noicons.webp";

import { subscribe } from "../api/apiCalls";

function Footer() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [errMessage, setErrorMessage] = useState("");

  const getYear = () => {
    return new Date().getFullYear();
  };

  const handleSub = () => {
    if (email === "" || email === null) {
      setError(true);
      setErrorMessage("Полето е задолжително");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (pattern.test(email)) {
        let newSub = {
          email: email,
        };
        subscribe(newSub).then(
          (res) => {
            if (res === 11000) {
              setError(true);
              setErrorMessage("Вашта емаил адреса е веќе регистрирана");
            } else {
              setError(false);
              setSubscribed(true);
              setTimeout(function () {
                setSubscribed(false);
              }, 5000);
            }
          },
          (err) => {
            setError(true);
          }
        );
      } else {
        setErrorMessage("Внесете валидна е-маил адреса");
        setError(true);
      }
    }
  };
  return (
    <div className="footer__container">
      <div className="footer__wrapper container">
        <div className="subscribe__newsletter">
          <div className="newsletter__text">
            <h3>Бидете први кој ќе дознаете</h3>
            <p>
              никогаш не пропуштајте ништо од <b>BuzzMobile - Store</b> со
              пријавување на нашиот информатор.
            </p>
          </div>
          <div>
            <div className="newsletter__form">
              <div style={{ width: "100%" }}>
                <TextField
                  label="Е-маил адреса"
                  variant="outlined"
                  className="order__input"
                  value={email}
                  type="email"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Button
                  variant="contained"
                  className="newsletter-btn"
                  style={{
                    backgroundColor: "var(--colorPrimary)",
                    color: "white",
                  }}
                  onClick={handleSub}
                >
                  Пријави се
                </Button>
              </div>
            </div>
            {error && (
              <Alert severity="error" style={{ marginBottom: "15px" }}>
                {errMessage}
              </Alert>
            )}
            {subscribed && (
              <Alert severity="success" style={{ marginBottom: "15px" }}>
                Успешно се пријавивте на нашиот информатор очекувајте новости на
                вашта е-маил адреса.
              </Alert>
            )}
          </div>
        </div>
        <div className="company__infos">
          <div className="company__logo">
            <img src={logo} alt="buzz mobile store" />
            <p>Премиум Мобилара, Сервис и продажба на мобилна галантерија</p>
            <div className="social__media__icons">
              <a
                href="https://www.facebook.com/buzzmobilestore"
                className="links"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/buzzmobilestore/"
                className="links"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.tiktok.com/@buzzmobilestore"
                className="links"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  fill="#47bd86"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="24px"
                  height="24px"
                >
                  {" "}
                  <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="site__links">
            <p>Мапа на сајтот</p>
            <a href="/" className="links">
              Почетна
            </a>
            <a href="/telefoni/Apple" className="links">
              Mобилни телефони
            </a>
            <a href="/galanterija/polnaci" className="links">
              Мобилна галантерија
            </a>
            <a href="/galanterija/ggluvcinja" className="links">
              Компјутерска галантерија
            </a>
            <a href="/kontakt" className="links">
              Контакт
            </a>
          </div>
          <div className="site__info">
            <p>Информации</p>
            <p>
              <FmdGoodIcon /> ул.Ленинова бр.90, Струмица
            </p>
            <p>
              <LocalPhoneIcon />{" "}
              <a
                className="links"
                style={{ textDecoration: "underline" }}
                href="tel:+38976810012"
              >
                076 810 012
              </a>{" "}
            </p>
            <p>
              <AlternateEmailIcon />{" "}
              <a className="links" href="mailto:contact@buzzmobile.mk">
                contact@buzzmobile.mk
              </a>
            </p>
          </div>
        </div>
        <div className="company__copyright">
          <p>
            Copyright © {getYear()} <b>Buzz Mobile Store.</b> All Rights
            Reserved.
          </p>
          {/* <p>
            Powered by:{" "}
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="links"
            >
              HiDigital
            </a>{" "}
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
