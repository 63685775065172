import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    wishlist: [],
  },
  reducers: {
    addWish: (state, action) => {
      state.wishlist.unshift(action.payload); // using unshift to add todo to the beginning
    },
    deleteWish: (state, action) => {
      // state.todos.filter((todo) => todo.id === action.payload);
      state.wishlist.splice(
        state.wishlist.findIndex((item) => item.id === action.payload),
        1
      );
    },
  },
});

export const { addWish, deleteWish } = userSlice.actions;

export default userSlice.reducer;
