import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import session from "redux-persist/lib/storage/session";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";

function CartItem({ product, deleteCartProduct, calculateTotalPrice }) {
  const [quantityN, setquantityN] = useState(product.quantity);
  const [totalPP, setTotalPP] = useState(
    parseFloat(product.price * product.quantity).toLocaleString("en")
  );

  useEffect(() => {
    setTotalPP(quantityN * product.price);
    updateCartSession();
  }, [quantityN]);

  const increaseQuantity = () => {
    if (quantityN < 15) {
      setquantityN(quantityN + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantityN > 1) {
      setquantityN(quantityN - 1);
    }
  };

  const updateCartSession = () => {
    let current = sessionStorage.getItem("cart");

    if (!current) {
      current = [];
    } else {
      current = JSON.parse(current);
    }

    const productIndex = current.findIndex((obj) => obj.id === product.id);

    current[productIndex].quantity = quantityN;

    sessionStorage.setItem("cart", JSON.stringify(current));

    calculateTotalPrice(current);
  };

  return (
    <div className="cart__grid__product">
      <div>
        <img src={product.img} alt="cart item img" height={100} />
      </div>
      {product.categories === "telefoni" ? (
        <Link to={"/product/" + product.id} className="links">
          <p style={{ textAlign: "left" }}>{product.title}</p>
        </Link>
      ) : (
        <Link to={"/product/accessories/" + product.id} className="links">
          <p style={{ textAlign: "left" }}>{product.title}</p>
        </Link>
      )}
      <p style={{ fontWeight: "500" }}>
        <span className="cart__price__mobile">цена:</span>
        {parseFloat(product.price).toLocaleString("en")} ден.{" "}
      </p>
      <div className="quantity__change">
        <AddIcon onClick={increaseQuantity} />
        <p>{quantityN}</p>
        <RemoveIcon onClick={decreaseQuantity} />
      </div>
      <p className="product__delete" style={{ fontWeight: "500" }}>
        {parseFloat(totalPP).toLocaleString("en")} ден.
        <DeleteOutlineIcon
          fontSize="medium"
          style={{ color: "#d32f2f", marginLeft: "5px" }}
          onClick={() => deleteCartProduct(product.id)}
        />
      </p>
    </div>
  );
}

export default CartItem;
