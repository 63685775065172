import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchFilter from "./SearchFilter";

function Navbar() {
  return (
    <nav className="header__desktop">
      <ul>
        <li>
          <a href="/">
            <HomeIcon fontSize="large" />
          </a>
        </li>
        <li>
          <a href="#">
            Мобилни телефони <KeyboardArrowDownIcon />
          </a>
          <ul className="dropdown" id="dropdown">
            <li>
              <a href="/telefoni/Apple">Apple</a>
            </li>
            <li>
              <a href="/telefoni/Samsung">Samsung</a>
            </li>
            <li>
              <a href="/telefoni/Huawei">Huawei</a>
            </li>
            <li>
              <a href="/telefoni/Xiaomi">Xiaomi</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            Мобилна галантерија <KeyboardArrowDownIcon />
          </a>
          <ul className="dropdown" id="dropdown">
            <li>
              <a href="/galanterija/polnaci">Полначи</a>
            </li>
            <li>
              <a href="/galanterija/wpolnac">Wireless полнач</a>
            </li>
            <li>
              <a href="/galanterija/adapteri">Адаптери</a>
            </li>
            <li>
              <a href="/galanterija/slusalki">Слушалки</a>
            </li>
            <li>
              <a href="/galanterija/kabli">Кабли за полнење</a>
            </li>
            <li>
              <a href="/galanterija/bzvucnici">Bluetooth звучници</a>
            </li>
            <li>
              <a href="/galanterija/dodatocizakola">Додатоци за кола</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            Компјутерска галантерија <KeyboardArrowDownIcon />
          </a>
          <ul className="dropdown" id="dropdown">
            <li>
              <a href="/galanterija/gslusalki">Слушалки</a>
            </li>
            <li>
              <a href="/galanterija/ggluvcinja">Глувчиња</a>
            </li>
            <li>
              <a href="/galanterija/gtastaturi">Тастатури</a>
            </li>
            <li>
              <a href="/galanterija/gpodlogi">Подлоги за маус</a>
            </li>
            <li>
              <a href="/galanterija/gzvucnici">Звучници</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#service">Сервис</a>
        </li>
        <li>
          <a href="/kontakt">Контакт</a>
        </li>
        <li>
          <SearchFilter />
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
