import React from "react";

//images
import sopro from "../assets/sopro1.webp";
import watch from "../assets/watch-ultra.webp";
import buds from "../assets/buds-pro.webp";
import samsung from "../assets/samsungs22ultra.webp";
import iphone14 from "../assets/iphone14.webp";

//swiperjs
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

//lazy load images

SwiperCore.use([Pagination, Navigation, Autoplay]);

function HomeBanner() {
  return (
    <div className="home-banner__container">
      <div class="hidden-title">
        <h1>BuzzMobile | Премиум Mobilara</h1>
      </div>
      <div className="home-banner__wrapper  container">
        <div className="img__col1">
          <Swiper
            className="mySwiper"
            style={{ width: "100%" }}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            loop={true}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img src={iphone14} alt="buzzmobile iphone14 pro max" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a href="/product/62288805d8262ae7718a305f">
                <img src={samsung} alt="buzzmobile iphone13 pro" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <a href="/product/62274e86971657c6de45f077" className="links">
                <img src={sopro} alt="buzzmobile iphone13 pro" />
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="img__col2">
          <a href="product/636acf58259591f37b6d829e" alt="apple watch ultra">
            <img src={watch} alt="buzzmobile smart watch" />
          </a>
          <a href="/galanterija/slusalki">
            <img src={buds} alt="buzzmobile galaxy buds" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
