import axios from "axios";

const BASE_URL = "https://buzzmobile-app.herokuapp.com/api";

// https://buzzmobile-app.herokuapp.com/api/products?category=telefoni&new=true

export const getFeaturedProducts = async (products) => {
  try {
    const res = await axios.get(`${BASE_URL}${products}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getSingleProduct = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/products/find/${id}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getSingleProductAcc = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/accessories/find/${id}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getProductsByBrand = async (brand) => {
  try {
    const res = await axios.get(`${BASE_URL}/products?brand=${brand}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getProductsByCategory = async (category) => {
  try {
    const res = await axios.get(`${BASE_URL}/accessories?category=${category}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getSearchResults = async (searchName) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/products/search?title=${searchName}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const submitOrder = async (order) => {
  try {
    const res = await axios.post(`${BASE_URL}/orders`, order);
    return res;
  } catch (err) {
    return err;
  }
};

export const subscribe = async (email) => {
  try {
    const res = await axios.post(`${BASE_URL}/subscribe`, email);

    return res;
  } catch (err) {
    return err.response.data.code;
  }
};
