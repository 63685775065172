import React, { useEffect, useState } from "react";
import { getSingleProductAcc } from "../api/apiCalls";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import OrderProduct from "./OrderProduct";

//swiperjs
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation, Thumbs]);

function SingleProductAcc() {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const location = useLocation();
  const productId = location.pathname.split("/")[3];
  const [firstLoad, setfirstLoad] = useState(true);

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);
    if (firstLoad) {
      getSingleProductAcc(productId).then(
        (res) => {
          if (isMounted) {
            setProduct(res);
            setLoading(false);
          }
        },
        (err) => {
          console.log(err);
        }
      );
      setfirstLoad(false);
    }

    if (product) {
      document.title = `${product.title} | Buzz Mobile Store`;
    }
    return () => {
      isMounted = false;
    };
  }, [product]);
  return (
    <div className="single__product__container container">
      {loading ? (
        <LinearProgress />
      ) : (
        <div className="single__product__wrapper">
          <div className="single__product__images">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                width: "100%",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Navigation, Thumbs]}
              className="single__product__gallery"
            >
              {product.img.map((item, index) => (
                <SwiperSlide>
                  <img src={item} alt="phone" key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              slidesPerView={3}
              modules={[Navigation, Thumbs]}
              className="single__product__thumbs"
              style={{ width: "100%" }}
            >
              {product.img.map((item, index) => (
                <SwiperSlide>
                  <img src={item} alt="phone" height={100} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="single__product__info">
            <div className="single__product__title">
              <p className="sp__title">{product.title}</p>
              <p className="sp__brand">{product.brand}</p>
            </div>

            <div className="single__product__price">
              <p className="sp__price">
                {" "}
                {parseFloat(product.price).toLocaleString("en")}{" "}
                <span>ден.</span>
              </p>
            </div>
            <OrderProduct
              title={product.title}
              price={product.price}
              img={product.img[0]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleProductAcc;
