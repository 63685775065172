import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import SearchFilter from "./SearchFilter";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Collapse from "@mui/material/Collapse";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import CloseIcon from "@mui/icons-material/Close";

import EarbudsBatteryIcon from "@mui/icons-material/EarbudsBattery";
import ComputerIcon from "@mui/icons-material/Computer";
import EmailIcon from "@mui/icons-material/Email";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

function NavbarMobile({ openMenu, fade, handleMenu }) {
  const [openp, setOpenp] = useState(false);
  const [openpg, setOpenpg] = useState(false);
  const [opengg, setOpengg] = useState(false);

  const handleClick = () => {
    setOpenp(!openp);
    setOpenpg(false);
    setOpengg(false);
  };
  const handleClickPG = () => {
    setOpenpg(!openpg);
    setOpenp(false);
    setOpengg(false);
  };
  const handleClickGG = () => {
    setOpengg(!opengg);
    setOpenpg(false);
    setOpenp(false);
  };

  return (
    <div className={"mobile__nav" + " " + (fade ? "fadeIn" : "fadeOut")}>
      <CloseIcon
        fontSize="large"
        style={{ marginLeft: "20px", color: "white" }}
        className="close-menu-icon"
        onClick={handleMenu}
      />
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "#1e1e29",
          color: "white",
          height: "100vh",
          marginTop: "35px",
        }}
        component="nav"
      >
        <ListItemButton component="a" href="/">
          <ListItemIcon>
            <HomeIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Дома" style={{ color: "white" }} />
        </ListItemButton>

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <PhoneIphoneIcon />
          </ListItemIcon>
          <ListItemText primary="Мобилни телефони" />
          {openp ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} component="a" href="/telefoni/Apple">
              <ListItemText primary="Apple" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/telefoni/Samsung"
            >
              <ListItemText primary="Samsung" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/telefoni/Huawei"
            >
              <ListItemText primary="Huawei" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/telefoni/Xiaomi"
            >
              <ListItemText primary="Xiaomi" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickPG}>
          <ListItemIcon>
            <EarbudsBatteryIcon />
          </ListItemIcon>
          <ListItemText primary="Мобилна галантерија" />
          {openpg ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openpg} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/polnaci"
            >
              <ListItemText primary="Полначи" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/wpolnac"
            >
              <ListItemText primary="Wireless полнач" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/adapteri"
            >
              <ListItemText primary="Адаптери" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/slusalki"
            >
              <ListItemText primary="Слушалки" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/kabli"
            >
              <ListItemText primary="Кабли за полнење" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/bzvucnici"
            >
              <ListItemText primary="Bluetooth звучници" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/dodatocizakola"
            >
              <ListItemText primary="Додатоци за кола" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickGG}>
          <ListItemIcon>
            <ComputerIcon />
          </ListItemIcon>
          <ListItemText primary="Компјутерска галантерија" />
          {opengg ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={opengg} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/gslusalki"
            >
              <ListItemText primary="Слушалки" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/ggluvcinja"
            >
              <ListItemText primary="Глувчиња" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/gtastaturi"
            >
              <ListItemText primary="Тастатури" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/gpodlogi"
            >
              <ListItemText primary="Подлоги за маус" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              component="a"
              href="/galanterija/gzvucnici"
            >
              <ListItemText primary="Звучници" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton component="a" href="/kontakt">
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Контакт" />
        </ListItemButton>
        <ListItemButton component="a" href="#service" onClick={handleMenu}>
          <ListItemIcon>
            <MiscellaneousServicesIcon />
          </ListItemIcon>
          <ListItemText primary="Сервис" />
        </ListItemButton>

        <ListItemButton>
          <ListItemIcon>
            <SearchFilter fontSize="large" onClick={handleMenu} />
          </ListItemIcon>
        </ListItemButton>

        <ListItemButton style={{ marginTop: "15px" }}>
          <ListItemIcon>
            <a
              href="https://www.instagram.com/buzzmobilestore/"
              className="links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon style={{ color: "white" }} />
            </a>
            <a
              href="https://www.facebook.com/buzzmobilestore"
              className="links"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon style={{ color: "white" }} />
            </a>
          </ListItemIcon>
        </ListItemButton>
      </List>
    </div>
  );
}

export default NavbarMobile;
