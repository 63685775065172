import React, { useEffect, useState } from "react";

import FeaturedCollectionProduct from "./FeaturedCollectionProduct";

//mui
import LinearProgress from "@mui/material/LinearProgress";
import { getFeaturedProducts } from "../api/apiCalls";
import FeaturedProductSkeleton from "./skeleton-render/FeaturedProductSkeleton";

function FeaturedCollection({ gear, results, featured, phone }) {
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  let skeletonCount = 8;

  useEffect(() => {
    let isMounted = true;

    getFeaturedProducts(featured).then(
      (res) => {
        if (isMounted) {
          let sortFeatured = res.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setProducts(sortFeatured);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div className="featured__collection__container mgTop">
      <div className="featured__collection__wrapper ">
        <h2 className="title__h2">{gear}</h2>
        {loading ? (
          <div className="featured__collection__products">
            {[...Array(skeletonCount)].map((e, i) => (
              <FeaturedProductSkeleton key={i} />
            ))}
          </div>
        ) : (
          <div className="featured__collection__products">
            {products.map((product, index) => (
              <FeaturedCollectionProduct
                product={product}
                key={index}
                category={false}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturedCollection;
