import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Cart from "./components/Cart";
import Wishlist from "./components/Wishlist";
import SingleProduct from "./components/SingleProduct";
import SingleProductAcc from "./components/SingleProductAcc";
import Category from "./components/Category";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Price from "./pages/Price";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/product/:productId" element={<SingleProduct />} />{" "}
        <Route
          path="/product/accessories/:productId"
          element={<SingleProductAcc />}
        />{" "}
        <Route path="/telefoni/:category" element={<Category />} />
        <Route path="/galanterija/:category" element={<Category />} />
        <Route path="/kontakt" element={<Contact />} />
        {/* nagradna igra ruti */}
        {/* <Route path="/winner/ZGlzYzEw" element={<Price discount={10} />} />
        <Route path="/winner/ZGlzYzIw" element={<Price discount={20} />} />
        <Route path="/winner/ZGlzYzMw" element={<Price discount={0} />} /> */}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
