import React from "react";

import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function SingleSearch(result) {
  return (
    <div className="single__search">
      {result.categories[0] === "telefoni" ? (
        <Link
          to={"/product/" + result._id}
          target="_blank"
          rel="noopener noreferrer"
          className="links"
        >
          <div className="single__search__wrapper">
            <div className="single__search__img">
              <img src={result.img[0]} alt={result.title} />
            </div>
            <div className="single__search__title">
              <p>{result.title}</p>
              {"priceStorage" in result ? (
                <p className="fp__price">
                  од{" "}
                  {parseFloat(result.priceStorage[0].price).toLocaleString(
                    "en"
                  )}{" "}
                  ден.
                </p>
              ) : (
                <p className="fp__price">
                  {parseFloat(result.price).toLocaleString("en")} ден.
                </p>
              )}
            </div>
            <ArrowForwardIosIcon className="search__icon-search" />
          </div>
        </Link>
      ) : (
        <Link
          to={"/product/accessories/" + result._id}
          target="_blank"
          rel="noopener noreferrer"
          className="links"
        >
          {" "}
          <div className="single__search__wrapper">
            <div className="single__search__img">
              <img src={result.img[0]} alt={result.title} />
            </div>
            <div className="single__search__title">
              <p>{result.title}</p>
              {"priceStorage" in result ? (
                <p className="fp__price">
                  од{" "}
                  {parseFloat(result.priceStorage[0].price).toLocaleString(
                    "en"
                  )}{" "}
                  ден.
                </p>
              ) : (
                <p className="fp__price">
                  {parseFloat(result.price).toLocaleString("en")} ден.
                </p>
              )}
            </div>
            <ArrowForwardIosIcon className="search__icon-search" />
          </div>
        </Link>
      )}
    </div>
  );
}

export default SingleSearch;
