import React from "react";

function SingleService(service) {
  return (
    <div>
      <img src={service.img} alt="battery-phone-icon" />
      <p className="service__title__mobile">{service.title}</p>
    </div>
  );
}

export default SingleService;
